<label class="group block relative pl-5 {{ noMargin ? '' : 'mb-3' }} cursor-pointer select-none">
  <input
    #checkbox
    type="checkbox"
    [id]="id"
    [ngModel]="value"
    (ngModelChange)="handleValueChange($event)"
    [disabled]="disabled"
    class="peer cursor-pointer absolute opacity-0 h-0 w-0"
  />
  <button
    (click)="checkbox.click()"
    class="checkmark absolute top-1 left-0 h-[18px] w-[18px] bg-white border-2 border-black border-solid rounded-sm pointer-events-none after:content-[''] after:absolute after:top-0 after:left-1 after:w-1.5 after:h-2.5 after:border-solid after:border-white after:border-r-2 after:border-b-2 after:rotate-45 after:hidden peer-checked:bg-black peer-checked:after:block peer-disabled:bg-disabled peer-disabled:border-disabled"
  >
    <div></div>
  </button>
  <span class="pl-1.5 peer-disabled:text-text-disabled"><ng-content></ng-content></span>
  <span class="pl-1.5 peer-disabled:text-text-disabled"><ng-content select="[remark]"></ng-content></span>
</label>
