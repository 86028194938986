import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output, booleanAttribute, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id?: string
  @Input() value?: boolean
  @Input() disabled = false
  @Input({ transform: booleanAttribute }) noMargin = false
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter()

  onChange: (val: boolean) => void = () => {}

  onTouched: () => void = () => {}

  writeValue(value: boolean): void {
    if (!this.disabled) {
      this.value = value
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  handleValueChange(value: boolean) {
    if (!this.disabled) {
      this.valueChange.next(value)
      this.onChange(value)
      this.writeValue(value)
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
  }
}
